.info_buttons_container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.info_buttons_wrap {
    width: var(--number-body-default-width);
    margin: auto;
    padding-top: 30px;
}

.info_buttons_container button {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 18px;
    color: var(--color-text-secondary);
    background-color: var(--color-primary);
    margin: 10px 4px 10px 4px;
    padding-top: 12px;
    padding-bottom: 8px;
    width: 200px;
    border-color: var(--color-primary);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
}

.game_manager_buttons button {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 18px;
    color: var(--color-text-secondary);
    background-color: var(--color-primary);
    margin: 6px 4px 6px 4px;
    padding-top: 12px;
    padding-bottom: 8px;
    width: 200px;
    border-color: var(--color-primary);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
}


.card {
    height: 80%;
}

.game_manager_space_bottom {
    height: 8rem;
}