.preloader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background);
    position: fixed; /* Alterado para 'fixed' */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    transition: opacity .6s ease-in-out;
    -webkit-transition: opacity .6s ease-in-out;
    -moz-transition: opacity .6s ease-in-out;
    -o-transition: opacity .6s ease-in-out;
}

.preloader_animate {
    width: 500px;
    height: 500px;
    background-image: url('./../../assets/images/zoomoo-animated-splash-screen.gif');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.removeOpacity {
    opacity: 0;
}

.preloader_animate img {
    /* width: 30rem; */
}

@media screen and (max-width: 450px) and (orientation: portrait) {
    .preloader_animate img {
        /* width: 20rem; */
    }
}

@media screen and (max-width: 914px) and (orientation: landscape) {
    .preloader_animate img {
        /* width: 20rem; */
    }
}

.subtitle {
    font-size: 1.5rem;
    position: absolute;
    bottom: 3rem;
}