@keyframes hidealert {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fullscreen-alert {
  box-sizing: border-box;
  position: fixed;
  right: 20%;
  bottom: 20px;
  z-index: 8000;

  width: 60%;
  height: 80px;
  padding: 15px;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);

  animation-name: hidealert;
  animation-duration: 6s;
}

.fullscreen-alert p {
  color: #000;
  font-size: 16px;
}