.auth_login_numpad {
    padding-top: 10px;
    padding-bottom: 10px;
}

.auth_login_numpad .teclado {
    /* display: none; */
}

.auth_login_numpad .linha {
    display: flex;
    justify-content: center;
}

.auth_login_numpad .campo-senha {
    border: 1px solid var(--color-white);
    height: 40px;
    font-size: 50px;
    border-radius: 6px;
    margin: 0px 60px 20px 60px;
    padding-top: 8px;
    letter-spacing: 5px;
    color: var(--color-primary);
}

.auth_login_numpad .button-pad {
    padding: 22px 28px 16px 28px;
    margin: 8px;
    font-family: "Intro Regular";
    font-size: 30px;
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-text-secondary);
    border: 0;
    border-radius: 7px;
    text-decoration: none;
}

.auth_login_numpad .button-erase {
    padding: 0px;
    margin: 8px 8px 8px 9px;
    width: 159px;
    height: 68px;
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-text-secondary);
    border: 0;
    border-radius: 7px;
    text-decoration: none;
    background-image: url('./../../assets/images/login-numpad-erase.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.auth_login_numpad .button-send {
    margin-top: 20px;
    padding: 0px;
    width: 196px;
    height: 50px;
    cursor: pointer;
    background-color: var(--color-white);
    color: var(--color-text-secondary);
    border: 0;
    border-radius: 7px;
    text-decoration: none;
    background-image: url('./../../assets/images/login-numpad-send.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.auth_login_numpad .auth-loading {
    background-image: url('./../../assets/images/preloader_button.gif');
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
}
