.section_background {
    position: absolute;
    width: 100%;
    -webkit-transition-property: height top;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: height top;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.section_frontground {
    opacity: 0.999;
    background-color: var(--color-gray);
}

.content-top-bg {
    height: 15rem;
    margin-bottom: -4rem;

    background-image: url('./../../assets/images/bg-content-top.png');
    background-position: center;
    background-size: 1024px 15rem;
    background-repeat: no-repeat;
}


.content-wrapper {
    background-image: url('./../../assets/images/bg-content-bottom.png');
    background-position: bottom;
    background-size: 1024px auto;
    background-repeat: repeat-y;
}

.section_backgrounds {
    background-image: url('./../../assets/images/bg-app-01.png');
    background-position: top;
    background-size: 1024px;
    background-repeat: no-repeat;
}

.section_bgColor {
    background-color: var(--color-background-main);
}
