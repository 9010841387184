.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.checkbox-wrapper p {
  font-size: initial;
  padding: 0 8px 0 10px;
}
.checkbox-wrapper img {
  padding: 5px;
  width: 40px;
  height: 40px;
}

.checkbox {
  width: 8px;
  height: 8px;
  border: solid 7px white;
  border-radius: 20px;
  align-self: center;
  cursor: pointer;
}
