.menu-container {
  max-width: 18em;
  width: 100%;
  margin: 0 auto;
}

.menu-letras {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

/* Estilo para os quadrados de letras */
.letra-quadrado {
  font-family: "Intro Regular";
  width: calc(16% - 3px); /* Calcula a largura de cada quadrado com espaço entre eles */
  height: 38px;
  background-color: var(--color-primary);
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  margin-bottom: 3px; /* Espaço inferior entre os quadrados */
  margin-right: 3px; /* Espaço à direita de cada quadrado */
  font-size: 18px;
  border-radius: 4px;
  text-decoration: none;
}

.menu-letras::after {
  content: '';
  flex: auto;
  width: calc(16% - 3px);
}