.fog_background {
  position: fixed;
  z-index: 8000;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.4);
}

.modal_container {
  display: flex;
  align-items: flex-end;

  height: 90vw;
  width: 90vw;
  max-height: 400px;
  max-width: 400px;

  bottom: 20px;

  z-index: 8000;

  background-color: var(--color-white);
  background-image: url(../images/pwa_zoomoo_notification-bg.png);
  background-position: center;
  background-size: cover;

  padding: 16px 32px;

  border-radius: 8px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.6);
}

@media (max-width: 720px) {
  .modal_container {
    padding: 8px;
  }
}

.modal_background {
  width: 100%;
}

.modal_footer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  width: 45%;
  height: 18%;

  margin: 5% 4%;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 45%;

  border-radius: 4px;
  border: none;

  background-position: center;
  background-size: cover;

  cursor: pointer;
}

.accept {
  background-image: url(../images/aceito-button.png);
}

.dismiss {
  background-image: url(../images/agora-nao-button.png);
}
